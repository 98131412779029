export const Types = [
    {
        "Type": "Transportes",
        "SubTypes": [
            {
                "SubType": "ACCIDENTE TRABAJADOR SIN BAJA",
                "ExternalCategory": "ACCIDENTE_TRABAJADOR_SB"
            },
            {
                "SubType": "ACCIDENTE TRABAJADOR CON BAJA",
                "ExternalCategory": "ACCIDENTE_TRABAJADOR_CB"
            },
            {
                "SubType": "AVERIA VEHICULO",
                "ExternalCategory": "AVERIA_VEHICULO"
            },
            {
                "SubType": "CONTAMINACION DE PRODUCTO",
                "ExternalCategory": "CONTAMINACION"
            },
            {
                "SubType": "DERRAME",
                "ExternalCategory": "DERRAME"
            },
            {
                "SubType": "DEFICIENCIA INSTALACION CARBURANTE",  
                "ExternalCategory": "DEFICIENCIA_INSTALA_CARB"
            },
            {
                "SubType": "FALTA CABIDA",
                "ExternalCategory": "FALTA_CABIDA"
            },
            {
                "SubType": "MEZCLA",
                "ExternalCategory": "MEZCLA"
            },
            {
                "SubType": "SOBRELLENADO DE COMBUSTIBLE CON DERRAME",
                "ExternalCategory": "SOBRELLENADO_COMB_COND"
            },
            {
                "SubType": "SOBRELLENADO DE COMBUSTIBLE SIN DERRAME",
                "ExternalCategory": "SOBRELLENADO_COMB_SIND"
            },
            {
                "SubType": "FALLO ESTANQUEIDAD",
                "ExternalCategory": "FALLO_ESTANQUEIDAD"
            },
            {
                "SubType": "SINIESTRO DE VEHICULOS",
                "ExternalCategory": "SINIESTRO_VEHICULO"
            },
            {
                "SubType": "DEFICIENCIA EN LA INSTALACION DE CARGA",
                "ExternalCategory": "DEFICIENCIA_INSTALACION"
            },
            {
                "SubType": "DEFICIENCIA DESCARGA CENTRAL TERMICA",
                "ExternalCategory": "DEFICIENCIA_DESCARGA_CT"
            },
            {
                "SubType": "DEFICIENCIA INSTALACION GLP",
                "ExternalCategory": "DEFICIENCIA_INSTALACION_GLP"
            },
            {
                "SubType": "SUGERENCIAS Y OBSERVACIONES",
                "ExternalCategory": "SUGERENCIAS_OBSERVA"
            },
            {
                "SubType": "TIEMPOS DE CONDUCCION",
                "ExternalCategory": "TIEMPOS_CONDUCCION"
            }, 
            {
                "SubType": "VELOCIDAD",
                "ExternalCategory": "VELOCIDAD"
            }, 
            {
                "SubType": "OTROS",
                "ExternalCategory": "OTROS"
            },
        ]
    },
    // {
    //     "Type": "Operaciones",
    //     "SubTypes": [
    //         {
    //             "SubType": "Accidente Industrial",
    //             "ExternalCategory": "ACCIDENTE_INDUSTRIAL"
    //         },
    //         {
    //             "SubType": "Incidente Industrial",
    //             "ExternalCategory": "INCIDENCTE_INDUSTRIAL"
    //         },
    //         {
    //             "SubType": "Accidente Medioambiental",
    //             "ExternalCategory": "ACCIDENTE_MEDIOAMBIENTAL"
    //         },
    //         {
    //             "SubType": "Incidente Medioambiental",
    //             "ExternalCategory": "INCIDENTE_MEDIOAMBIENTAL"
    //         },
    //         {
    //             "SubType": "Accidente laboral",
    //             "ExternalCategory": "ACCIDENTE_LABORAL"
    //         },
    //         {
    //             "SubType": "Tráfico",
    //             "ExternalCategory": "TRAFICO"
    //         },
    //         {
    //             "SubType": "Problemas tecnicos",
    //             "ExternalCategory": "PROBLEMAS_TECNICOS"
    //         },
    //         {
    //             "SubType": "Accidentes de calidad",
    //             "ExternalCategory": "ACCIDENTE_CALIDAD"
    //         },
    //         {
    //             "SubType": "Incidentes de calidad",
    //             "ExternalCategory": "INCIDENTE_CALIDAD"
    //         },
    //         {
    //             "SubType": "Condiciones metereologicas adversas",
    //             "ExternalCategory": "CONDICIONES_METEOROLOGICAS"
    //         },
    //         {
    //             "SubType": "Vandalismo",
    //             "ExternalCategory": "VANDALISMO"
    //         },
    //         {
    //             "SubType": "Otros",
    //             "ExternalCategory": "OPERACIONES_OTROS"
    //         }
    //     ]
    // }
]


